<template>
  <div>

    <!-- 表格 -->
    <div class="res-open-content type-ssc yz5f">
      <div class="r-o-ssc">
        <table class="com-table">
          <tr>
            <th>期数</th>
            <th width="140">时间</th>
            <th>第一球</th>
            <th>第二球</th>
            <th>第三球</th>
            <th>第四球</th>
            <th>第五球</th>
            <th colspan="3">总和</th>
            <th>龙虎</th>
            <th>前三</th>
            <th>中三</th>
            <th>后三</th>
          </tr>
          <tr v-for="item in list" :key="item.Expect">
            <td>{{item.Expect}}</td>
            <td>{{item.Opentime|substrSome}}</td>
            <td v-for="(code,index) in item.codeArr" :key="index" class="td-ball">
              <div :class="'lotball b'+code"></div>
            </td>
            <td :class="fontColor[n]" v-for="(n,index) in item.lutuResult" :key="index">{{n}}</td>
          </tr>
        </table>
        <div class="page">
          <!-- :page-sizes="[20,50,100,200]" -->
          <el-pagination @current-change="handleCurrentChange" :current-page="pages.pagenum" :page-size="pages.pagecount" layout="total, prev, pager, next, jumper" :total="totals">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import kjmixins from "../mixins/kjmixins";
export default {
  name: "kjsx",
  props: [""],
  mixins: [kjmixins],
  data() {
    return {};
  },

  components: {},
  created() {},
  computed: {},

  methods: {},
};
</script>
<style  scoped>
.r-o-ssc .com-table tr td.dx_draw,
.r-o-ssc .com-table tr td.lh_d {
  color: #4bb134;
}
.r-o-ssc .com-table tr td.ds_s,
.r-o-ssc .com-table tr td.dx_x,
.r-o-ssc .com-table tr td.lh_h {
  color: #00f;
}
.r-o-ssc .com-table tr td.ds_d,
.r-o-ssc .com-table tr td.dx_d,
.r-o-ssc .com-table tr td.lh_l,
.r-o-ssc .com-table tr td.qzh_d,
.r-o-ssc .com-table tr td.qzh_s2,
.r-o-ssc .com-table tr td.qzh_z {
  color: red;
}
</style>